<template>
  <div class="gift-list-edit">
    <header>
      <m-page-header title="礼品卡列表" router="gift-list" type="link" />
    </header>
    <main>
      <div class="goods-module">
        <h5 class="goods-module-title">商品</h5>
        <div class="goods-wrap">
          <div class="goods">
            <m-form-skeleton :loading="listLoading" type="goods">
              <m-goods
                :img="goodsConf.img"
                :href="goodsConf.href"
                :detail="goodsConf.detail"
                v-if="goodsConf.img"
              />
              <div v-else class="empty-wrap">
                <el-empty :image-size="70" description="请选择商品"></el-empty>
              </div>
            </m-form-skeleton>
          </div>
          <div class="control">
            <el-button type="primary" size="small" @click="showPop">添加商品</el-button>
            <m-select-goods
              v-if="isShowPop"
              :visible="isShowPop"
              @close="close"
              @selectOk="selectOk"
              chooseType="radio"
            />
          </div>
        </div>
      </div>
      <div class="list-module">
        <m-table :data="dataList" :loading="listLoading">
          <el-table-column label="规格">
            <template #default="tableItem">
              <div v-html="tableItem.row.sku_value || '单规格'"></div>
            </template>
          </el-table-column>
          <el-table-column prop="stock_nums" label="库存">
            <template #default="tableItem">
              <span :style="{ fontWeight: 'bold' }">{{ tableItem.row.stock_nums || '—' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="券码(前缀)">
            <template #default="tableItem">
              <template v-if="tableItem.row.offer_id">
                <div>前缀 : {{ tableItem.row.prefix }}</div>
                <div>
                  <a class="m-link" @click="checkCouponCode(tableItem.row.offer_id)">
                    ( 查看券码 )
                  </a>
                </div>
              </template>
              <template v-else> — </template>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template #default="tableItem">
              <template v-if="tableItem.row.offer_id == 0">—</template>
              <el-tag v-else-if="tableItem.row.offer_status == 1">正常</el-tag>
              <el-tag type="danger" v-else-if="tableItem.row.offer_status == 0">已过期</el-tag>
              <el-tag type="info" v-else-if="tableItem.row.offer_status == 2">已失效</el-tag>
              <template v-else>—</template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220">
            <template #default="tableItem">
              <el-button size="small" @click="selectCoupon(tableItem.row, tableItem.$index)">
                选择优惠券
              </el-button>
              <el-button
                size="small"
                v-show="tableItem.row.offer_id"
                @click="emptyCoupon(tableItem.row)"
              >
                取消选择
              </el-button>
            </template>
          </el-table-column>
        </m-table>

        <el-dialog title="选择优惠券" v-model="isShowCouponPop" :width="500">
          <div class="select-coupon-wrap" style="color: #333">
            <h5 :style="{ marginBottom: '16px' }">优惠券</h5>
            <el-select v-model="curSelectCoupon" placeholder="请选择优惠券" @change="couponChange">
              <el-option label="请选择优惠券" :value="0"></el-option>
              <el-option
                v-for="item in couponList"
                :key="item.id"
                :label="item.base_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button size="medium" @click="isShowCouponPop = false">取 消</el-button>
              <el-button size="medium" type="primary" @click="saveCoupon">确 定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <coupon-code-list
        :visible="isShowDrawer"
        :offerId="checkCouponId"
        @closeDrawer="closeDrawer"
      />
    </main>
    <footer>
      <m-form-footer :loading="saveLoading" @save="onSave" />
    </footer>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';

import MPageHeader from '@/components/pageHeader/PageHeader';
import MFormFooter from '@/components/formFooter/FormFooter';
import MGoods from '@/components/goods/Goods';
import MTable from '@/components/table/Table';
import MSelectGoods from '@/components/selectGoods/Index.vue';
import MFormSkeleton from '@/components/formSkeleton/FormSkeleton';
import CouponCodeList from '../components/couponCodeList/CouponCodeList';
import { api, getRoute } from '../../../tools/config';

import {
  getEditListData,
  getListData,
  getSelectData,
  saveEditData,
  saveCreateData,
} from '../api/giftListEdit';
export default {
  name: 'GiftListEdit',
  components: {
    MPageHeader,
    MFormFooter,
    MGoods,
    MSelectGoods,
    MTable,
    MFormSkeleton,
    CouponCodeList,
  },
  setup() {
    const { state } = useStore();
    const router = useRouter();

    const isShowPop = ref(false);
    const showPop = () => {
      isShowPop.value = true;
    };
    const close = () => {
      isShowPop.value = false;
    };

    // 初始化商品配置，用于组件MGoods
    const goodsConf = ref({
      img: '',
      href: '',
      detail: [
        {
          text: 'Fashion Sexy Women Bathing Suit CrochetBikini Swimwear',
          type: 'link,bold',
          href: '//www.baidu.com',
        },
        { text: 'HU2344345' },
        { text: 'US$536.99', type: 'bold' },
      ],
    });
    const curGoodsId = ref(0); // 当前选择商品的 id
    const dataList = ref([]);
    const listLoading = ref(false);
    // 当 域名 或 goodsId 发生变化时动态设置 商品的href
    watchEffect(() => {
      goodsConf.value.detail[0].href = `//${state.storeDomain}/h-product-detail.html?goods_id=${curGoodsId.value}`;
      goodsConf.value.href = `//${state.storeDomain}/h-product-detail.html?goods_id=${curGoodsId.value}`;
    });
    // 选择商品
    const selectOk = async goods => {
      const { image, base_name, codeno, price, id } = goods.value;
      goodsConf.value.img = image;

      curGoodsId.value = id;

      // 设置商品的配置，用于组件MGoods
      goodsConf.value.detail[0].text = base_name;
      goodsConf.value.detail[1].text = codeno;

      // 获取商品相关的列表信息
      const res = await getListData({ goods_id: id }, listLoading);

      const { goods_sku } = res.data.list;

      dataList.value = goods_sku;

      // 设置商品的配置，用于组件MGoods
      goodsConf.value.detail[2].text = price;
    };

    const route = useRoute();
    // 编辑的时候才执行该函数
    const getEditData = async () => {
      const id = route.params.giftId;
      if (!id) {
        return;
      }

      const res = await getEditListData({ id }, listLoading);

      const { goods_sku, goods_info } = res.data.list;
      dataList.value = goods_sku;

      goodsConf.value.img = goods_info.image;

      curGoodsId.value = goods_info.id;

      // // 设置商品的配置，用于组件MGoods
      goodsConf.value.detail[0].text = goods_info.base_name;
      goodsConf.value.detail[1].text = goods_info.codeno;
      goodsConf.value.detail[2].text =
        goods_info.symbol_status == 1
          ? goods_info.symbol_left + goods_info.price
          : goods_info.price + goods_info.symbol_left;
    };

    let curSelectRow;
    const curSelectCouponPrefix = ref(''); // 当前选中的优惠券
    const curSelectCoupon = ref();
    const isShowCouponPop = ref(false);
    const curSelEndTime = ref(); // 当前选中的优惠券的到期时间
    const couponList = ref([]); // 优惠券下拉框

    const selectCoupon = row => {
      // 记录当前的 券码前缀。后续如果没有选择优惠券，则使用该券码
      curSelectCouponPrefix.value = row.prefix;

      curSelectRow = row;
      isShowCouponPop.value = true;

      // 记录当前的优惠券的 结束时间。后续如果没有选择优惠券，则使用该结束时间来计算 优惠券状态
      const index = couponList.value.findIndex(item => {
        return item.id === row.offer_id;
      });
      if (index >= 0) {
        curSelEndTime.value = couponList.value[index].end_time * 1000;
      }

      if (row.offer_status == 0 || row.offer_status == 2) {
        curSelectCoupon.value = null;
      } else {
        curSelectCoupon.value = row.offer_id;
      }
    };

    // 选择框

    const setCoponList = async () => {
      const res = await getSelectData();
      couponList.value = res.data.list;
    };
    const couponChange = val => {
      curSelectCoupon.value = val;

      if (val === 0) {
        curSelectCouponPrefix.value = '';
        return;
      }
      // 设置当前选中项的name
      curSelectCouponPrefix.value = couponList.value.find(item => {
        return item.id === val;
      }).prefix;

      // 查找当前选中的优惠券的到期时间
      const { end_time } = couponList.value.find(item => {
        return item.id === val;
      });
      curSelEndTime.value = parseInt(end_time) * 1000;
    };
    const saveCoupon = () => {
      curSelectRow.prefix = curSelectCouponPrefix.value;
      curSelectRow.offer_id = curSelectCoupon.value || 0;
      isShowCouponPop.value = false;
      if (typeof curSelEndTime.value === 'undefined') {
        curSelectRow.offer_status = '';
      } else {
        curSelectRow.offer_status = curSelEndTime.value <= new Date().getTime() ? 0 : 1;
      }
    };
    // 取消选择（清空选择的优惠券）
    const emptyCoupon = row => {
      row.offer_id = 0;
      row.prefix = '';
    };

    const saveLoading = ref(false);
    const backRoute = getRoute('gift-list');
    // 保存
    const onSave = async () => {
      const data = {
        id: parseInt(route.params.giftId),
        goods_id: curGoodsId.value,
        sku_list: {},
      };

      let isAllowReq = false;
      dataList.value.forEach(item => {
        data.sku_list[item.sku_code] = item.offer_id || 0;
        if (item.offer_id) {
          isAllowReq = true;
        }
      });

      if (!isAllowReq) {
        ElMessage.warning('请至少选择一个优惠券');
        return;
      }

      if (data.id) {
        const res = await saveEditData(data, saveLoading);

        if (res.status === 1) {
          ElMessage.success('礼品卡修改成功');
          router.push(backRoute);
        }
      } else {
        const res = await saveCreateData(data, saveLoading);

        if (res.status === 1) {
          ElMessage.success('礼品卡创建成功');
          router.push(backRoute);
        }
      }
    };

    const isShowDrawer = ref(false);
    const checkCouponId = ref(0);
    // 查看券码
    const checkCouponCode = id => {
      isShowDrawer.value = true;
      checkCouponId.value = id;
    };

    const closeDrawer = () => {
      isShowDrawer.value = false;
    };

    return {
      onSave,
      saveLoading,
      isShowPop,
      close,
      showPop,
      selectOk,
      goodsConf,
      curGoodsId,
      listLoading,
      dataList,
      getEditData,
      selectCoupon,
      isShowCouponPop,
      curSelectCoupon,
      couponList,
      setCoponList,
      couponChange,
      saveCoupon,
      emptyCoupon,
      api: api(),
      state,
      isShowDrawer,
      checkCouponCode,
      checkCouponId,
      closeDrawer,
    };
  },
  mounted() {
    this.getEditData();
    this.setCoponList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.gift-list-edit {
  position: relative;
  padding-bottom: 60px;

  main {
    .goods-module {
      padding: 40px 30px;
      padding-bottom: 0px;
      &::after {
        content: '';
        display: block;
        width: calc(100% + 60px);
        height: 10px;
        background-color: $bodyBg;
        position: relative;
        left: -30px;
      }
      .goods-module-title {
        padding-bottom: 20px;
      }
      .goods-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .goods {
          flex: 0 0 600px;
          width: 0;

          .empty-wrap {
            display: flex;
            justify-content: center;

            .el-empty {
              padding: 0;
            }
          }
        }
      }
    }
    .list-module {
      padding: 20px;
      padding-bottom: 0px;
    }
    .select-coupon-wrap {
      color: #333;
    }
  }
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
:deep(.el-dialog__body) {
  min-height: auto;
  overflow: visible;
}
</style>
