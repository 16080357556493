<template>
  <div class="coupon_code_list">
    <el-drawer
      v-model="drawer"
      direction="ttb"
      destroy-on-close
      size="100%"
      @close="closeDrawer"
      title="查看券码"
    >
      <main>
        <div class="control_bar">
          <div class="search_wrap">
            <el-input
              placeholder="请输入邮箱或券码"
              v-model="searchQuery"
              class="input_with_select"
            >
              <template #append>
                <el-button icon="el-icon-search" @click="search"></el-button>
              </template>
            </el-input>
          </div>
        </div>
        <div class="list_wrap">
          <m-table
            :data="dataList"
            :loading="listLoading"
            :pagination="pagination"
            @currentChange="changePage"
          >
            <el-table-column prop="couponCode" label="优惠券号码"></el-table-column>
            <el-table-column prop="baseName" label="活动名称"></el-table-column>
            <el-table-column prop="userEmail" label="拥有者"></el-table-column>
            <el-table-column prop="createTime" label="获得时间"></el-table-column>
            <el-table-column prop="usingTime" label="使用时间"></el-table-column>
            <el-table-column prop="status" label="状态" width="160">
              <template #default="tableItem">
                <el-tag v-if="tableItem.row.status == 0" type="danger">不可用</el-tag>
                <el-tag v-else-if="tableItem.row.status == 1">未使用</el-tag>
                <el-tag v-else type="warning">已使用</el-tag>
              </template>
            </el-table-column>
          </m-table>
        </div>
      </main>
    </el-drawer>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from 'vue';

import MTable from '@/components/table/Table';
import { getCouponCodeList } from '../../api/giftListEdit';
import { parseTime } from '../../../../tools';
export default {
  props: ['visible', 'offerId'],
  components: {
    MTable,
  },
  setup(props, { emit }) {
    const drawer = ref(false);
    watchEffect(() => (drawer.value = props.visible));

    const closeDrawer = () => {
      emit('closeDrawer');
    };

    const dataList = ref([]);
    const listLoading = ref(false);
    const searchQuery = ref('');
    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPage: 1,
    });

    const setDataList = async (page = 1, keywords = '') => {
      const res = await getCouponCodeList(
        {
          offer_id: props.offerId,
          base_name: keywords,
          page_size: pagination.value.pageSize,
          page,
        },
        listLoading,
      );

      if (!res.status) return;
      dataList.value = [];

      const { list } = res.data;

      pagination.value.total = parseInt(res.data.page_list.total);
      pagination.value.currentPage = res.data.page_list.page;

      list.res.forEach(item => {
        const dataItem = {
          couponCode: item.coupon_code,
          baseName: list.offer_res[item.offer_id].base_name,
          userEmail: list.user[item.user_id] || '—',
          createTime: parseTime(item.create_time * 1000, '{y}-{m}-{d}'),
          usingTime: item.using_time == 0 ? '—' : parseTime(item.using_time * 1000, '{y}-{m}-{d}'),
          status: item.status,
        };
        dataList.value.push(dataItem);
      });
    };

    watch(drawer, cur => {
      if (!cur) return;
      setDataList();
    });

    const search = () => {
      setDataList(1, searchQuery.value);
    };

    // 换页
    const changePage = cur => {
      setDataList(cur, searchQuery.value);
    };
    return {
      drawer,
      listLoading,
      dataList,
      searchQuery,
      search,
      setDataList,
      closeDrawer,
      pagination,
      changePage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.coupon_code_list {
  main {
    overflow: auto;
    height: calc(100vh - 65px);
    .control_bar {
      padding: 20px;
      border-top: $cutLine;
      .search_wrap {
        width: 300px;
      }
    }
    .list_wrap {
      padding: 0 20px;
    }
  }
  :deep(.el-drawer__header) {
    margin: 0;
    padding-bottom: 20px;
    span {
      font-size: 16px;
      color: #333;
    }
  }
}
</style>
